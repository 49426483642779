/* global __BUILD__:true */
import { io } from 'socket.io-client'
import { getCookie } from '../lib/liveagent-ui-common'
import { log } from '../lib/logger'

var session = {
  events: ['activated', 'streamReady'],
  listeners: [],
  environment: '',
  get id() {
    return getCookie('idSession')
  },
  set id(idSession) {
    document.cookie = 'idSession=' + idSession + ';path=/'
  },
  user: '',
  roles: [],
  customers: [],
  subCustomers: [],
  build: __BUILD__,
  server_build: '',
  fullname: '',
  email: '',
  config: {},
  environments: [],
  server_types: { list: [] },
}

session.socket = io()

var uuidCheck = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

session.validate = function validateStoredSessionId(type) {
  if (session.id) {
    log(6, 'validating session : ' + session.id)
    if (session.id.match(uuidCheck)) {
      session.socket.emit('handshake', {
        id: session.id,
        type: type,
        build: session.build,
      })
    } else {
      log(3, session.id + ' is not a valid session id')
      session.end()
    }
  } else {
    log(5, 'no session to validate')
    session.end()
  }
}

session.on = function addListener(eventType, eventHandler) {
  if (!eventType || session.events.indexOf(eventType) === -1) {
    log(3, eventType + ' is not a valid event type')
    return false
  }

  if (typeof eventHandler !== 'function') {
    log(3, eventHandler + ' is not a valid event handler')
    return false
  }

  session.listeners.push({
    eventType: eventType,
    eventHandler: eventHandler,
  })
}

session.emit = function notifyListener(eventType, eventBody) {
  if (!session.events.indexOf(eventType) === -1) {
    log(3, eventType + ' is not a valid event type')
    return false
  }

  var listener

  for (var i = 0, l = session.listeners.length; i < l; i++) {
    listener = session.listeners[i]
    if (listener.eventType === eventType || listener.eventType === '*') {
      listener.eventHandler(eventBody)
    }
  }
}

session.end = function endSession(reason) {
  reason = reason ? reason : 'loggedoff'
  if (session.id) {
    log(6, 'dropping session :' + reason)
    //      debugger;

    if (reason === 'loggedoff' || reason === 'issues') {
      session.socket.emit('loggedoff', session.id)
    }

    destroySession()
  }

  if (
    window.name === 'liveagent-operator' ||
    window.name === 'liveagent-manager' ||
    window.name === 'liveagent-dashboard'
  ) {
    window.close()
  } else if (!window.location.pathname.match(/login/)) {
    //      window.setTimeout(function redirect(){
    window.location.href = '/login/#' + reason
    //      }, 40);
  }
}

function destroySession() {
  log(6, 'destroying session')

  document.cookie.split(';').forEach(function(c) {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
  })

  session.id = ''
  session.role = ''
  session.password = ''
}

session.socket.on('unlock', function setUserName(eventData) {
  log(6, 'session is valid', eventData)
  session = Object.assign(session, eventData)
  session.emit('activated')
})

session.socket.on('die', session.end)

export default session
